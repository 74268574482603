import React, { useState } from "react";

import ProjectCard from "../components/ProjectCard";

import ProjectsData from "../data/projects.json";
import CarouselControls from "../components/Carousel/CarouselControls";

const Projects: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const numProjects = ProjectsData.projects.length;
  const project = ProjectsData.projects[activeIndex];

  return (
    <React.Fragment>
      <ProjectCard
        name={project.name}
        url={project.url}
        hero={project.hero}
        tags={project.tags}
        linkable={project.linkable}
        description={project.description}
      />
      <CarouselControls
        backClick={() => setActiveIndex(Math.abs((activeIndex - 1) % numProjects))}
        forwardClick={() => setActiveIndex((activeIndex + 1) % numProjects)}
        numItems={numProjects}
        activeIndex={activeIndex}
      />
    </React.Fragment>
  );
};

export default Projects;

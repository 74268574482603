import React from "react";

import BackChevron from "./chevron-back-outline.svg";
import ForwardChevron from "./chevron-forward-outline.svg";

import styles from "./index.module.css";

interface CarouselControlsProps {
  numItems: number;
  activeIndex: number;
  backClick?: () => void;
  forwardClick?: () => void;
}

const CarouselControls: React.FC<CarouselControlsProps> = ({
  numItems,
  activeIndex,
  backClick,
  forwardClick
}) => {
  return (
    <div className={styles.container}>
      <img onClick={backClick} className={styles.directional} src={BackChevron} />
      <div className={styles.dots}>
        {[...Array(numItems)].map((_, i) => {
          const activeClassName =
            i == activeIndex ? `${styles.dot} ${styles.dotActive}` : styles.dot;
          return <span key={i} className={activeClassName} />;
        })}
      </div>
      <img onClick={forwardClick} className={styles.directional} src={ForwardChevron} />
    </div>
  );
};

export default CarouselControls;

import React from "react";

import TechnologyTag from "./TechnologyTag";

interface ProjectDetails {
  name: string;
  url: string;
  hero: {
    type: string;
    data: string;
  };
  linkable: boolean;
  description: string;
  tags: string[];
}

const ProjectCard: React.FC<ProjectDetails> = ({ name, hero, description, tags, url, linkable }) => {
  const renderHero = () => {
    if (hero.type == "image") {
      return <img style={{maxWidth: "100%"}} src={hero.data}/>
    } else if (hero.type == "innerHTML") {
      return <div style={{maxWidth: "100%"}} dangerouslySetInnerHTML={{__html: hero.data}}/>
    } else if (hero.type == "video") {
      return (
        <video autoPlay loop style={{maxWidth: "100%"}}>
          <source src={hero.data} type="video/mp4"/>
        </video>
      ) 
    }
  }

  return (
    <div style={{ marginBottom: 20 }}>
      {renderHero()}
      {linkable ? <a href={url}>
        <h2>{name}</h2>
      </a> : <h2>{name}</h2>}
      <p>{description}</p>
      <div>
        {tags.map((tag, i) => (
          <TechnologyTag text={tag} key={i} />
        ))}
      </div>
    </div>
  );
};

export default ProjectCard;
